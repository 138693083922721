*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  background: #121212;
}

/* MAO ones */
.app-background {
  margin: 1vh auto 1vh;
  border-radius: 15px;
  background: #1d2532;
  text-align: center;
  max-width: 95vw;
  box-shadow: 3px 10px 10px rgba(0, 0, 0, 0.704);
}

.app-background-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.app-background-header-title {
  align-items: center;
  padding: 1em 1.5em;
}

.app-background-header-title a {
  text-decoration: none;
}

.app-background-header-title h1 {
  margin: auto;
  color: white;
}

.app-container {
  align-items: center;
  width: 100%;
  text-align: center;
  margin-bottom: 1vh;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 2px;
  text-transform: uppercase;
}

.app-nav-button {
  padding: 1em 0.5em;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
}

.app-nav-button a {
  text-decoration: none;
  color: #9fb6d7;
  font-weight: bold;
}

.app-nav-button a[aria-pressed="true"] {
  color: #decb18;
}

.app-nav-button a:hover {
  border-bottom: 2px solid #decb18;
}

.content-container {
  color: white;
  padding: 5px 2vw 5vh 2vw;
  margin: 5px auto;
  text-align: center;
  max-width: 60em;
}

/* Mint View */
.mint-view-container {
  width: 100%;
  justify-content: center;
}

.mint-view-intro-text {
  text-align: left;
  font-size: 1.5em;
  line-height: 1.5em;
  max-width: 40em;
  margin: auto;
}

.mint-view-intro-text a {
  color: orange;
}

.mint-view-intro-text a:visited {
  color: lightskyblue;
}

.mint-view-img-container {
  width: 100%;
  background: white;
  justify-content: center;
  border: 1px solid white;
  border-radius: 2px;
  border-width: 1vmin 1vmin 1vmin 1vmin;
  box-shadow: 3px 10px 10px rgba(0, 0, 0, 0.704);
  padding-bottom: 56%; /* 16:9 */
  height: 0;
  position: relative;
}

.mint-view-img-container img {
  width: 100%;
  background: white;
  border-radius: 5px;
}

.mint-view-img-info {
  color: black;
}

.mint-view-progress {
  padding: 10px;
}

.mint-view-actions {
  display: flex;
  flex-direction: row;
  color: white;
  margin: 5px auto 2px auto;
}

.mint-view-actions-tokenid {
  padding: 2px;
  margin: 2px;
  width: 50%;
}

.mint-view-actions-price {
  padding: 2px;
  margin: 2px;
  width: 50%;
}

.mint-view-actions-randomize {
  cursor: pointer;
  border-radius: 5px;
  background-color: darkslategray;
  color: white;
  padding: 2px;
  margin: 2px;
  width: 50%;
  height: 100%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
}

.mint-view-actions-randomize:hover {
  transform: scale(1.01);
  transition-duration: 0.05s;
}

.mint-view-actions-mint {
  cursor: pointer;
  border-radius: 5px;
  background-color: darkgreen;
  color: white;
  padding: 2px;
  margin: 2px;
  width: 50%;
  height: 100%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
}

*/ .mint-view-actions-mint:hover {
  transform: scale(1.01);
  transition-duration: 0.05s;
}

.mint-view-tooltip {
  float: right;
  padding: 5px;
  margin-right: 10px;
  font-style: italic;
  font-size: 14px;
}

.notdisplayed {
  opacity: 0;
}

.displayed {
  width: fit-content;
  height: 20px;
  transition: 0.8s;
  opacity: 1;
}

/* Wallet View */
.wallet-view-container {
  width: 100%;
}

.wallet-view-no-nft-button {
  text-decoration: none;
  color: white;
}

.wallet-view-no-nft-button-text {
  font-weight: 400;
  font-size: 15px;
  padding: 10px;
  margin: 30px auto 20px auto;
  width: fit-content;
  background-color: darkgreen;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  border-radius: 7px;
}

.wallet-image-container {
  width: 100%;
  margin: 10px 0 15px 0;
  background: white;
  justify-content: center;
  border: 1px solid white;
  border-radius: 2px;
  border-width: 1vmin 1vmin 1vmin 1vmin;
  box-shadow: 3px 10px 10px rgba(0, 0, 0, 0.704);
}

.wallet-image-container img {
  width: 100%;
  background: white;
  border-radius: 5px;
}

.wallet-image-container-details {
  color: black;
}

.wallet-image-container-detail-message {
  color: rgba(255, 255, 255, 0.861);
  margin: 5px;
  padding: 15px 15px 15px 15px;
  font-style: italic;
  background: #3f4449;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
}

.wallet-image-container-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.wallet-image-container-actions-button {
  cursor: pointer;
  border-radius: 5px;
  background-color: darkgreen;
  color: white;
  margin: 5px;
  padding: 5px 12px 5px 12px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  font-size: 0.75em;
}

/* FAQ View */
.faq-view-container {
  width: 100%;
  padding: 5px 3vw 30px 3vw;
}

.faq-container {
  margin: 10px 0;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.704);
}

.faq-accordion {
  background-color: #051227;
  text-align: left;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  transition: 0.4s;
  font-size: 18px;
  font-weight: 600;
}

.faq-panel {
  padding: 0 18px;
  background-color: #193b72;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-out;
}

.faq-answer {
  padding: 15px 5px;
  text-align: justify;
  line-height: 1.5em;
  font-size: 16px;
}

.faq-accordion:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: '\2796';
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.faq-view-container p {
  text-align: justify;
  line-height: 24px;
  font-size: 17px;
}

.faq-view-container h2 {
  padding: 15px 0 0 0;
}

.faq-view-container img {
  max-width: 75%;
  border: 4px solid white;
  border-width: 5px 5px 10px 5px;
  margin: 10px 0 5px 0;
}

.faq-view-container a {
  color: orange;
}

.faq-view-container a:visited {
  color: lightskyblue;
}

/* Gallery View */
.row {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 20px 0;
}

/* Create four equal columns that sit next to each other */
.column {
  flex: 33%;
  max-width: 33;
  padding: 10px 0 10px 0;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50;
  }
}

@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}

/* .gallery-image-container img {
  width: 30vw;
} */

.faq-view-container-pagenav {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid orange;
  /* border: 1px solid green; */
}

/* .faq-view-container-pagenav-buttons { */
/* border-top: 1px solid orange; */
/* border: 1px solid orange; */
/* } */

.faq-view-container-pagenav-button {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
}

/* Releases View */
.releases-view-container {
  width: 100%;
}

.releases-view-container-message {
  width: 100%;
  color: rgba(255, 255, 255, 0.861);
  margin: 10px 5px 10px 5px;
  padding: 5px 15px 5px 15px;
  font-style: italic;
  background: #051227;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
}

.releases-view-container-message p {
  text-align: justify;
}

.releases-view-container-message a {
  color: orange;
}

.releases-view-container-message a:visited {
  color: lightskyblue;
}



/* Wallet */
.metamask-wallet {
  width: fit-content;
  align-items: center;
  display: flex;
  margin: 0 0 0 0.5em;
}

.metamask-wallet-connect {
  background: #f5841e;
  border-radius: 10px;
  padding: 0.25em 0.5em;
  cursor: pointer;
  box-shadow: 0 7px 5px rgba(0, 0, 0, 0.704);;
}

.metamask-wallet-account {
  background: #f5841e;
  border-radius: 10px;
  padding: 0.25em 0.5em;
  cursor: pointer;
  box-shadow: 0 7px 5px rgba(0, 0, 0, 0.704);
}

.metamask-wallet-account-unsupported {
  background: red;
  border-radius: 10px;
  padding: 0.25em 0.5em;
  cursor: pointer;
  box-shadow: 0 7px 5px rgba(0, 0, 0, 0.704);
  align-items: center;
}

/* Change Form */

.change-form {
  width: 100%;
  transition: 800ms;
}

.change-form-container {
  margin: 4px;
  background: black;
  padding: 10px 40px 10px 40px;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
}

.change-form-container-header {
  width: 100%;
  color: white;
  font-weight: bolder;
  text-align: center;
}

.change-form-container-text {
  border: 1px solid lightgrey;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 2px 5px 2px 5px;
  margin: 5px;
  background: transparent;
  transition: 1s;
  color: white;
  width: 70%;
}

.change-form-container-submit {
  width: 25%;
  justify-content: center;
  align-items: center;
  background-color: darkgreen;
  color: white;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
  border: none;
  font-weight: 700;
  transition: 1s;
  outline: none;
  cursor: pointer;
}

.show {
  display: block;
  transition: 800ms;
  opacity: 1;
}

.active.show {
  display: block;
  transition: 800ms;
  opacity: 0;
}

/* Change Confirmation */

.change-confirmation-text {
  color: black;
  font-style: italic;
  font-size: 12px;
  width: 100%;
}

/* Mao View */

.mao-image-container {
  width: 100%;
  margin: 10px 0 15px 0;
  background: white;
  justify-content: center;
  border: 1px solid white;
  border-radius: 2px;
  border-width: 1vmin 1vmin 1vmin 1vmin;
  box-shadow: 3px 10px 10px rgba(0, 0, 0, 0.704);
}

.mao-image-container img {
  width: 100%;
  background: white;
  border-radius: 5px;
}

.mao-image-container-details {
  color: black;
}

.mao-view-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: white;
  margin: 5px 0 2px 0;
}

/* HomeView */

.home-view-container {
  width: 100%;
  justify-content: center;
}

.home-view-container img {
  max-width: 90%;
  border: 4px solid white;
  border-width: 5px 5px 10px 5px;
  margin: 10px 0 20px 0;
}

.vl-left {
  border-left: 6px solid grey;
  height: 5vh;
  margin-left: 25%;
}

.vl-right {
  border-right: 6px solid grey;
  height: 5vh;
  margin-right: 25%;
}

.home-intro-message {
  /* border: 1px solid yellow; */
  border-left: 5px solid #f5841e;
  /* width: 60vw; */
  max-width: 90%;
  margin: 0 auto 15px auto;
  padding: 0 5px 0 10px;
  text-align: left;
  /* background: darkslategray; */
  box-shadow: 3px 10px 10px rgba(0, 0, 0, 0.704);
}

.home-intro-message h2 {
  /* border: 1px solid red; */
  margin: 0;
  font-size: 1.15em;
  padding: 2px 2px 5px 2px;
}

.home-intro-message p {
  /* border: 1px solid green; */
  margin: 0;
  padding: 2px;
}

.home-view-container a {
  text-decoration: none;
  color: #f5841e;
  font-weight: bold;
  padding: 5px 5px 5px 5px;
}

.footer {
  color: white;
  padding: 10px;
  max-width: 1000px;
  margin: 1vh auto 1vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-social-container {
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 0px 1vh 0px;
}

.footer-social-container img {
  max-height: 3vh;
}

.footer-social-container a {
  cursor: pointer;
}
